<template>
  <div style="margin-left:30px;margin-right:30px;">
    <h2><center>Additional Charge</center></h2>
    <br/><br/>
    <b-card
      no-body
      class="mb-0"
    >
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex  justify-content-start mb-1 mb-md-0"

      >
      <b-row style="margin:10px;">
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
      </b-row>

      </b-col>
    </b-row>
      <b-col
        cols="12"
        md="8"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >

      </b-col>
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex  justify-content-start mb-1 mb-md-0"

      >
      <b-table
        ref="refSearchSutNumber"
        class="position-relative"
        :items="searchResList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
      <template v-for="field in searchResList"
      v-slot:cell(suitNumber)="field">
        <div class="text-nowrap">
          {{ field.item.suitNumber }}
        </div>
      </template>
      <template #cell(name)="data">
        <div class="text-nowrap">
          {{ data.item.firstName + " " + data.item.lastName }}
        </div>
      </template>
      <template v-for="field in searchResList"
      v-slot:cell(packageNo)="field">
       <div v-if="field.item.shippingPackages.length>0"  v-for="sp in field.item.shippingPackages">
         <div @click="onSelectShippingPackage(sp)" style="cursor: pointer;color:blue;" class="text-nowrap" >
          {{ sp.packageNo }}
          </div>
        </div>
        <div v-else class="text-nowrap" >
          -
        </div>
        
      </template>

    </b-table>
    </b-col>
    <b-col
      cols="12"
      md="6"
      class=" align-items-center justify-content-start mb-1 mb-md-0"
    >
    <b-row v-if="selectedShippingPackage">
      <b-card
        title="Shipping Package Details"
        style="border:solid 1px;#c9c9c9;width:95%;"
      >
        <b-card-text >
          <b-row>
            <b-col>PackageNo:{{selectedShippingPackage.packageNo}}</b-col>
            <b-col></b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>NumberOfItems:{{selectedShippingPackage.numberOfItems}}</b-col>
            <b-col>packageDimensions:{{selectedShippingPackage.packageDimensions}}</b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>weight:{{selectedShippingPackage.weight}}</b-col>
            <b-col>volumetricWeight:{{selectedShippingPackage.volumetricWeight}}</b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>shippingStatus:{{selectedShippingPackage.shippingStatus}}</b-col>
            <b-col>paymentStatus:{{selectedShippingPackage.paymentStatus}}</b-col>
          </b-row>
          <b-row class="mt-2">
            <b-button
              variant="primary"
              @click="addCharges(selectedShippingPackage)"
            >
              <span class="text-nowrap">Add Additional Charges</span>
            </b-button>
          </b-row>

        </b-card-text>
      </b-card>
    </b-row>
    <b-row v-if="selectedShippingPackage">
      <b-card
        title="Additional Charges"
        style="border:solid 1px;#c9c9c9;width:95%;"
      >
      <b-table
        ref="refSearchSutNumber"
        class="position-relative"
        :items="additionalCharges"
        responsive
        :fields="chargetableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
      <template #cell(title)="data">
        <div class="text-nowrap">
          {{ data.item.title }}
        </div>
      </template>
      <template #cell(amount)="data">
        <div class="text-nowrap">
          {{ data.item.amount }}
        </div>
      </template>

    </b-table>

    </b-card>
    </b-row>
    <b-row v-if="selectedShippingPackage">
      <b-card
        title="Items in the Package"
        style="border:solid 1px;#c9c9c9;width:95%;"
      >
      <b-table
        ref="refSearchSutNumber"
        class="position-relative"
        :items="getItem"
        responsive
        :fields="itemColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
      <template #cell(title)="data">
        
      </template>

    </b-table>

    </b-card>
    </b-row>
    


    </b-col>
    </b-row>
    <div class="m-2">
      <b-modal
        ref="NewCharge"
        title="Add New Additional Charge"
        v-model="showAddCharge"

      >
      <validation-observer ref="registerForm">
        <b-form class="auth-register-form">
          <div>
          <b-form-group  label="title" label-for="register-title">
            <validation-provider #default="{ errors }" name="title" rules="required">
              <b-form-input class="mb-1" id="register-title" v-model="newCharge.title" name="register-title" placeholder="Enter Title"
                :state="errors.length > 0 ? false:null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
          <!-- amount -->
          <b-form-group  label="Amount" label-for="register-amount">
            <validation-provider #default="{ errors }" name="amount" rules="required">
              <b-form-input  class="mb-1" id="register-amount" v-model="newCharge.amount" name="register-amount" placeholder="Enter Amount"
                :state="errors.length > 0 ? false:null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div style="float:right">
            <b-button  class="mt-1" variant="primary" size="lg" type="submit" @click.prevent="saveNewCharge">
              Save
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-modal>
    </div>

    </b-card>
  </div>

</template>
<script>
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules';

extend('required', required);
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BPopover,BCardText
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { mapMutations, mapActions, mapState } from "vuex";
import BCardCode from '@core/components/b-card-code';

export default {
  data(){
    return {
      searchQuery:"",
      skip:0,
      limit:5,
      tableColumns :[
        { key: 'suitNumber'},
        { key:'warehouseAddress'},
        { key:'name'},
        { key:'packageNo'},

        // { key: 'actions' }
      ],
      selectedShippingPackage:null,
      // additionalCharges: [],
      chargetableColumns:[
        {key:'title'},
        {key:'amount'}
      ],
      itemColumns:[
        {key:'id'},
        {key:'itemName'},
        {key:'quantity'},
        {key:'value'}

      ],
      newCharge:{
        title:'',
        amount:0
      },
      showAddCharge:false,
      shippingPackages: ''
    }
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BPopover,
    vSelect,
    BCardCode,
    BCardText,
    ValidationProvider,
    ValidationObserver,

  },
  watch:{
    searchQuery:{
      handler(){
        console.log("searchQuery value = "+this.searchQuery);
        this.searchFormQuery()
      }
    }
  },
  computed:{
    ...mapState({
      searchResList :(state) =>{
        //console.log("searchRes in searchResList"+JSON.stringify(state.additionalcharges.searchResList))
        // shippingPackages = state.additionalCharges.searchResList[0].shippingPackages
        return state.additionalcharges.searchResList
      },
      shippingPackages :(state) =>{
        //console.log("searchRes in searchResList"+JSON.stringify(state.additionalcharges.searchResList))
        return state.additionalcharges.searchResList
      },
      totalSerachRes :(state) =>{
        return state.additionalcharges.totalSerachRes
      },
      additionalCharges :(state) =>{
        return state.additionalcharges.additionalCharges
      },
      getItem :(state) =>{
        return state.additionalcharges.Items
      }
    })
  },
  methods:{
    ...mapActions({
      loadSearchRes:"additionalcharges/loadSearchRes",
      addNewAdditionalCharge:"additionalcharges/addNewCharge",
      getItemsForPackage: "additionalcharges/getItems",
      setPriceAfterAdditionalCharges: "additionalcharges/updatePrice"
    }),
    ...mapMutations({
      setAdditionalCharges:'additionalcharges/setAdditionalCharges'
    }),
    searchFormQuery(){
      this.loadSearchRes({$search:this.searchQuery})
    },
    onSelectShippingPackage(selectedPackage){
      this.selectedShippingPackage = selectedPackage;
    //  this.additionalCharges = selectedPackage.additionalCharges;
      this.setAdditionalCharges({additionalCharges:selectedPackage.additionalCharges});
      // var date = moment(this.selectedShippingPackage.date).format("YYYY-MM-DD");
      // this.selectedShippingPackage.date = date;
      console.log("selectedPackage onSelectShippingPackage()"+JSON.stringify(selectedPackage))
      this.getItemsForPackage(selectedPackage.id)
    },
    addCharges(selectedShippingPackage){
      this.showAddCharge = true;
      // console.log("selected shipping packages in addiitonal chrages", selectedShippingPackage.id, "..", this.additionalCharges[this.additionalCharges.length-1].amount)
      // this.setPriceAfterAdditionalCharges({id: selectedShippingPackage.id, discount: this.additionalCharges[this.additionalCharges.length-1].amount})
    },
    async saveNewCharge() {
      const comp = this;
      var success = await this.$refs.registerForm.validate()
      if (success) {
        await comp.addNewAdditionalCharge({newChargeObj : comp.newCharge,packageId:this.selectedShippingPackage.id});
        comp.newCharge.title = '';
        comp.newCharge.amount = 0;
        this.showAddCharge = false;

        }
    }


  }
}

</script>
<style>
.modal .modal-footer {
    padding: 0;
    border: none;
}
.modal .modal-footer > .btn {
    /* margin-left: 0.75rem; */
    display: none;
}

</style>
